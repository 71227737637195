import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
const catalogue = ['/photo-print', '/Photoframe', '/personalized-products', '/e-commerce-product', '/selfie-album', '/passportsize-photo', '/corporate-gift']
@Injectable({
  providedIn: 'root'
})
export class RouterActiveService {
  private routerSubject$: BehaviorSubject<any> = new BehaviorSubject<any>('')
  public activeRouter = this.routerSubject$.asObservable()
  constructor(
    private router: Router,
    private title: Title) { }


  setRoute(data: any) {
    let path = String(data)
    if (catalogue.some((ele: any) => String(ele) == String(data))) {
      this.routerSubject$.next('Catalogue')
    } else {
      this.routerSubject$.next('')
    }

  }
}

import { Injectable } from "@angular/core";

import { EmployeeAdapter } from "./employee-adapter.service";
import { IEmployee } from "../model/employee.model";
import { Adapter } from "app/core/adapters";

@Injectable({
    providedIn: "root",
  })

export class ApiAdapter implements Adapter<IEmployee> {
    constructor(private employeeadapter: EmployeeAdapter) {}
  
    adapt(response: any): IEmployee {
      let employeeList = [];
      response.data.forEach((row) => {
        employeeList.push(this.employeeadapter.adapt(row));
      });
  
      return {
        keyword: response.keyword,
        message: response.message,
        employee: employeeList,
        pageSize: response.count ?? 0,
        page: response.page,
      };
    }
  }
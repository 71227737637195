import { Injectable } from "@angular/core";
import { ICompanyAPI } from "app/core/models/company/api.model";
import { Adapter } from "../../adapters";
import { CompanyAdapter } from "./company.adapter.service";

@Injectable({
    providedIn: "root"
})

export class ApiAdapter implements Adapter<ICompanyAPI> {

    constructor(private adapterPackage: CompanyAdapter) {

    }

    adapt(response: any): ICompanyAPI {

        let userData = this.adapterPackage.adapt(response.data);
        
        return {
            keyword: response.keyword,
            message: response.message,
            company: userData,
            pageSize: response?.count ?? 0,
            page: response?.page ?? 0,
        }
    }

}
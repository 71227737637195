import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontSizeDirective } from './font-size.directive';
import { ScrollToDirective } from './scroll-to.directive';
import { AppDropdownDirective } from './dropdown.directive';
import { DropdownAnchorDirective } from './dropdown-anchor.directive';
import { DropdownLinkDirective } from './dropdown-link.directive';
import { tgssSideNavToggleDirective } from './tgss-side-nav-toggle.directive';
import { tgssSidenavHelperDirective, tgssSidenavTogglerDirective } from './tgss-sidenav-helper/tgss-sidenav-helper.directive';
import { tgssHighlightDirective } from './tgss-highlight.directive';
import { TwoDigitDecimaNumberDirective } from './twodigitdecimal';
import { DotDirective } from './dot.directive';



const directives = [
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  tgssSideNavToggleDirective,
  tgssSidenavHelperDirective,
  tgssSidenavTogglerDirective,
  tgssHighlightDirective,
  TwoDigitDecimaNumberDirective,
  DotDirective
]

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: directives,
  exports: directives
})
export class SharedDirectivesModule { }
export const environment = {
  production: false,
  EmpapiURL: 'https://testapi.theprintapp.com/api/V1/EP/', //Development 
  apiURL: 'https://testapi.theprintapp.com/api/V1/AP/', //Development 
  socketUrl: 'https://testchat.theprintapp.com/', //Development 
  pickup_postcode:'625014',

  // EmpapiURL: 'https://api.theprintapp.com/api/V1/EP/', //staging
  // apiURL: 'https://api.theprintapp.com/api/V1/AP/', //staging
  // socketUrl: 'https://chat.theprintapp.com/', //staging

  firebaseConfig: {
    apiKey: "AIzaSyBr1Pe3cqR5pTG_45MM0WCZK3ZZVYHVID8",
    authDomain: "fir-notifi-4deeb.firebaseapp.com",
    projectId: "fir-notifi-4deeb",
    storageBucket: "fir-notifi-4deeb.firebasestorage.app",
    messagingSenderId: "954777974196",
    appId: "1:954777974196:web:4205a951f08c9ace4feee1",
    measurementId: "G-SY0HZ7JWER"
  },
};



export class Employee {
    public id? : number;
    public employeeType? : string;
    public name : string;
    public photo : any;
    public photourl: any;
    public department? : string;
    public departmentname?: string;
    public mobile? :string;
    public email? : string;
    public status? : any;
    public created_on?: any;
    public employee_code?: String;
    public work_status?: string;
  }
  
import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { EmployeeApiService } from "./employee.api.service";

const conversationList: any[] = [];

@Injectable({
  providedIn: "root",
})
export class ChatSocketService {
  constructor(private apiservice: EmployeeApiService) {}

  getConversationList(request: any): Observable<any> {
    let params = new HttpParams();
    for (let data in request) {
      params = params.append(data, request[data]);
    }
    return this.apiservice.get(`chat/conversation`, params);
  }

  getChatDetail(request: any): Observable<any> {
    let params = new HttpParams();
    for (let data in request) {
      params = params.append(data, request[data]);
    }
    return this.apiservice.get(`employee/chat/list`, params);
  }

  getToUserId(id: any): Observable<any> {
    return this.apiservice.get(`get/userId/${id}`);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-loader',
  templateUrl: './product-loader.component.html',
  styleUrls: ['./product-loader.component.css']
})
export class ProductLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

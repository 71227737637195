import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IMenuItem } from "../models/navigation.model";

@Injectable({
  providedIn: "root"
})

export class NavigationService {

  visible: boolean;
  iconMenu: IMenuItem[] = [];
  public notificationWidgetSubject = new BehaviorSubject<any>('');
   visibilityChange$: Observable<boolean>;

  constructor() {
    this.visible = true;
  }

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";

  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>([] as IMenuItem[]);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  publish(menu: IMenuItem[]) {
    this.menuItems.next([]);
    this.menuItems.next(menu);
  }

  hide() { this.visible = false; }

  show() { this.visible = true; }
  
  sendNotificationWidget(payload) {
    
    this.notificationWidgetSubject.next(payload)
  }
  
  getNotification() {
    return this.notificationWidgetSubject.asObservable();
  }

  getVisibilityStatus(): Observable<boolean> {
    return this.visibilityChange$;
  }


}

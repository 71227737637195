import { Injectable } from "@angular/core";
import { Company } from "app/core/models/company/company.model";
import { Adapter } from "../../adapters";


@Injectable({
    providedIn: "root"
})

export class CompanyAdapter implements Adapter<Company> {
   model: Company;
    adapt(item: any): Company {
        this.model =  new Company();    
        this.model.id = item?.id ?? 0,
        this.model.name = item?.name ?? '';
        this.model.email = item?.aud ?? '';
        this.model.mobile = item?.mobile ?? '';
        this.model.token = item?.token ?? '';
        this.model.skybind = item?.rolepermission ?? '';
        this.model.roleId = item?.role_id ?? '';
        this.model.otp = item?.otp ?? '';
        return this.model;
    }
}
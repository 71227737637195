import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { saveToken, destroyToken, hasToken, decodedToken, clearSessionData } from '../helpers/token.helper';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { get } from "lodash";
import { PORTALTYPE } from "../helpers/enum.helper";
import { ApiAdapter } from './company/api.adapter.service';
import { Company } from '../models/company/company.model';
import { ICompanyAPI } from '../models/company/api.model';

@Injectable({
  providedIn: 'root'
})

export class CompanyService {

  private currentCompanySubject = new BehaviorSubject<Company>({} as Company);
  public currentCompany = this.currentCompanySubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  portalType = '';
  constructor(
    private apiService: ApiService,
    private router: Router,
    private adapter: ApiAdapter,
  ) {

    this.isAuthenticatedSubject.next(hasToken());
    this.currentCompanySubject.next(this.getSessionUser());

  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  signin(formData): Observable<ICompanyAPI> {
    return this.apiService.post('CompanyLogin', formData).pipe(map(response => this.adapter.adapt(response)));
  }

 
  forgetpassword(formData): Observable<ICompanyAPI> {
    return this.apiService.post('CompanyForget', formData);
  }


  setAuth(company: Company) {
    // Save JWT sent from server in localstorage
    saveToken(company.token);
    window.localStorage.setItem('sesUsr', JSON.stringify(company));
    // Set current Company data into observable
    this.currentCompanySubject.next(company);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    destroyToken();
    clearSessionData();
    // Set current user to an empty object
    this.currentCompanySubject.next({} as Company);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);

  }


  sendResetLink(model: Company): Observable<ICompanyAPI> {
    let formData = new FormData();
    formData.append('email', model.email);
    return this.apiService.post('forget', formData).pipe(map(response => this.adapter.adapt(response)));
  }


  resetPassword(formData): Observable<ICompanyAPI> {
    return this.apiService.post('CompanyReset', formData);
  }


  chagePassword(model: Company): Observable<ICompanyAPI> {
    const formData = new FormData();
    const token = decodedToken();
    formData.append('old_password', model.oldPassword);
    formData.append('new_password', model.newPassword);
    formData.append('email', get(token, 'aud'));
    return this.apiService.post('CompanyChangePassword', formData);
  }



  getCurrentUser(): Company {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  get isLoggedIn() {
    return this.isAuthenticatedSubject.asObservable();
  }

  logout() {
    this.getPortalType();
    this.purgeAuth();
    this.goToLogin();
  }

  getSessionUser(): Company {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  goToLogin() {
    if (this.portalType == PORTALTYPE.ADMIN) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.COMPANY) { 
      this.router.navigate(['/company']); //default
    }
  }




  getPortalType() {
    const token = decodedToken();
    this.portalType = get(token, 'portal') ?? '';
  }



}
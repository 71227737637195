<div *ngIf="showNotificationFloat && data">
<div [ngClass]="isNotificationFloat==false ? '':'notification-float'" class="float-button {{position}}" [matMenuTriggerFor]="menu">
  <button mat-button class="new-ani-chat-butttom notification_btn_float">
    <span class="icon-wrap">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" class="notification_svg">
        <g class="too-big-actually">
          <g class="bell-whole">
            <path class="bell-part bell-part--ringer" d="M9.5,17.5a2,2,0,0,0,2-2h-4A2,2,0,0,0,9.5,17.5Z"/>
            <path class="bell-part bell-part--main" d="M16.23,12.82c-.6-.65-1.73-1.62-1.73-4.82a4.93,4.93,0,0,0-4-4.85V2.5a1,1,0,0,0-2,0v.65A4.94,4.94,0,0,0,4.5,8c0,3.2-1.13,4.17-1.73,4.82a1,1,0,0,0-.27.68,1,1,0,0,0,1,1h12a1,1,0,0,0,1-1A1,1,0,0,0,16.23,12.82Z"/>
          </g>
        </g>
      </svg>
    </span>
  </button>
  <p class="ani_text_1">New Notification Received</p>
</div>
</div>

<mat-menu class="notification-menu" #menu="matMenu" yPosition="above" backdropClass="chat" (menuOpened)="onMenuOpened()"
(menuClosed)="onMenuClosed()" >
  <mat-card class="p-0 m-0 notification-quick-view">
    <mat-card-header>
      <mat-card-title class="d-flex" style="justify-content: space-between;">
        <div>
          <p>Notifications</p>
        </div>
        <span class="text-right placement-icons">
          <mat-icon (click)="onMenuClosed()">close</mat-icon>
          <button mat-icon-button [matMenuTriggerFor]="menu1"  (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
        </span>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content >
      <mat-nav-list class="notification-list" role="list">
        <mat-list-item *ngFor="let n of [1] | slice:0:15; let i = index" class="notific-item" role="listitem">
          <div class="dynamic_img">
            <img style="width: 28px; margin-right: 10px;" src="{{data?.image}}" alt="">
          </div>
          <!-- {{data|json}} -->
          <!-- <a> -->
            <div class="mat-list-text">
              <small class="time text-muted">{{data?.title}}</small>
              <h4 matTooltip="{{data?.title}}" aria-label="text that displays a tooltip" class="message">
                {{data?.body ? data?.body:''}}
              </h4> 
            </div>
          <!-- </a> -->
        </mat-list-item>
      </mat-nav-list>
      <a class="notification_link" (click)="viewAll()">View all</a>
    </mat-card-content>
  </mat-card>
</mat-menu>


<mat-menu #menu1="matMenu">
  <button mat-menu-item (click)="updatePosition('top-left')">
    <span>Top Left</span>
  </button>
  <button mat-menu-item (click)="updatePosition('top-right')">
    <span>Top Right</span>
  </button>
  <button mat-menu-item (click)="updatePosition('bottom-right')">
    <span>Bottom Right</span>
  </button>
  <button mat-menu-item (click)="updatePosition('bottom-left')">
    <span>Bottom Left</span>
  </button>
</mat-menu>


import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from '@angular/router';
import { PORTALTYPE } from 'app/core/helpers/enum.helper';
import { decodedToken } from 'app/core/helpers/token.helper';
import { checkNull } from 'app/core/services/date-and-time-validators.service';
import { NavigationService } from 'app/core/services/navigation.service';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-notification-widget',
  templateUrl: './notification-widget.component.html',
  styleUrls: ['./notification-widget.component.css']
})
export class NotificationWidgetComponent implements OnInit {
  position = 'bottom-right';
  isNotificationFloat: boolean = false;
  showNotificationFloat: boolean = false;
  portalType: any;
  inForeground: boolean = false;
  constructor(
    public navServ: NavigationService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) { }
  data: any;
  ngOnInit(): void {

    this.navServ.getVisibilityStatus().subscribe((vis) => {
      this.inForeground = vis;
    })


    const token = decodedToken();
    this.portalType = get(token, 'portal') ?? '';
    this.navServ.getNotification().subscribe((res) => {





      if (!checkNull(res)) {
        return
      }

      console.log(this.inForeground, 'inForeground');

      if (!this.inForeground) {
        console.log(res.data, 'in background res.data');
        console.log(res, 'res');
        let audio = new Audio('assets/audio/notification.wav');
        audio.play().catch(error => {
          console.error('Error playing sound:', error);
        });
        this.isNotificationFloat = true;
        this.showNotificationFloat = true;
        this.cd.detectChanges()
        this.data = res.data;
        this.data['image'] = this.setimages(res?.data?.page);
        setTimeout(() => {
          this.isNotificationFloat = false;
        }, 3000);
      } else {
        console.log(res.data, 'in foreground res.data');
        console.log(res, 'res');

        let audio = new Audio('assets/audio/notification.wav');
        audio.play().catch(error => {
          console.error('Error playing sound:', error);
        });
        this.isNotificationFloat = true;
        this.showNotificationFloat = true;
        this.cd.detectChanges()
        this.data = res;
        this.data['image'] = this.setimages(res?.page);
        setTimeout(() => {
          this.isNotificationFloat = false;
        }, 3000);
      }
    })
  }

  updatePosition(newPosition: string): void {
    this.position = newPosition;
  }

  onMenuOpened(): void {


  }

  onMenuClosed(): void {

    this.showNotificationFloat = false
  }

  setimages(ele: any) {
    switch (ele?.page) {
      case "order_page": return 'assets/images/notification/order_placed.svg';
      case "order_cancelled": return 'assets/images/notification/cancel1.png'
      case "Order_cancel": return 'assets/images/notification/cancel1.png'
      case 'order_delivered': return 'assets/images/notification/delivered.png'
      case 'order_ready_for_dispatch': return 'assets/images/notification/ready_to_dispatch.svg'
      case 'order_dispatched': return 'assets/images/notification/dispatched.png'
      case 'attachment_approved': return 'assets/images/notification/approved.png'
      case 'attachment_rejection': return 'assets/images/notification/disapproved.png'
      case 'customer_preview': return 'assets/images/notification/preview.png'
      case 'new_contest_post': return 'assets/images/notification/bell.png'
      case 'notify_me': return 'assets/images/notification/notify.png'
      case 'new_product': return 'assets/images/notification/product_create.png';
      case 'out_of_stock': return 'assets/images/notification/outofstock.png';

      case 'register': return 'assets/images/notification/new_user_registered.svg';

      //admin icons
      case 'bulk_order_enquiry_create_mobile': return 'assets/images/notification/request_bulkorder.svg';
      case 'bulk_order_enquiry_create_website': return 'assets/images/notification/request_bulkorder.svg';
      case 'amount_paid_by_customer': return 'assets/images/notification/amt_paidcustomer.svg';
      case 'quote_reraised_request': return 'assets/images/notification/quote_reraise.svg';

      //employee icons

      case 'enquiry_assigned': return 'assets/images/notification/enquiry_assign.svg';
      case 'quote_reraised_approved': return 'assets/images/notification/reraised_approval.svg';
      case 'quote_reraised_rejected': return 'assets/images/notification/quote_rejection.svg';
      case 'enquiry_revoke': return 'assets/images/notification/enquiry_revoke.svg';

      case 'qc_approved': return 'assets/images/notification/quality_control_approved.svg';
      case 'qc_rejected': return 'assets/images/notification/quality_control_rejected.svg';
      case 'task_assign': return 'assets/images/notification/task_assign.svg';
      case 'quote_assigned': return 'assets/images/notification/quote_assigned.svg';
      case 'chat_with_admin': return 'assets/images/notification/chat_with_admin.svg';
      case 'customer_preview_approved': return 'assets/images/notification/customer_preview_approved.svg';
      case 'customer_preview_rejection': return 'assets/images/notification/customer_preview_rejection.svg';

      default: return 'assets/images/notification/bell.png'
    }
  }

  viewAll() {
    if (this.portalType === PORTALTYPE.ADMIN) {
      this.router.navigate(['/notification/list']);
    } else if (this.portalType === PORTALTYPE.EMPLOYEEE) {
      this.router.navigate(['/employee/notification/list']);
    }
  }

}

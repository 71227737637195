<div class="">
    <div class="audio " style="justify-content: center;">
        <span *ngIf="audioElement?.paused"
            (click)="audioElement?.play();hitGetDuration()"><mat-icon>play_arrow</mat-icon></span>
        <span *ngIf="!audioElement?.paused" (click)="audioElement?.pause()"><mat-icon>pause</mat-icon></span>
        <input type="range" id="volume" name="volume" min=0 max=100 #seeker (change)="seekDuration(seeker?.value)"
            [value]="percentage" style="height: 4px; width: 85px;">
        <span class="duration ml-8">{{duration}}</span>
        <span class="close_rec" *ngIf="type==1" (click)="clearAudio()"><i class="fa fa-times"
                aria-hidden="true"></i></span>
        <span class="ml-8" (click)="downloadFile()"><i class="fas fa-arrow-alt-circle-down"></i></span>
    </div>
</div>
<audio controls #audioElement hidden id="audioElement{{id}}" (playing)="getDuration()">
    <source [src]="checkNull(audioSrc) ? audioSrc :''">
</audio>
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { io, Socket } from "socket.io-client";
import { environment } from "environments/environment";
import { ApiService } from "./api.service";
@Injectable({
  providedIn: "root",
})
export class SocketIoService {
  private baseURL: string = environment.socketUrl;
  public socket: Socket;
  progress: number;
  constructor(private http: HttpClient, private apiService: ApiService) {}
  /***********1.Connect Node socket using Authgaurd with( userID) && (usertype) as query params************/

  connectSocket(userID: any, userType: any): Observable<any> {
    console.log("call hitted");
    this.socket = io(this.baseURL, {
      query: { sessionUserId: userID, userType: userType },
      forceNew: true,
      timeout: 30000,
      transports: ["polling"],
    });
    return new Observable((observer) => {
      this.socket.on("connect-response", (data: any) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
        console.log("socket disconnected------>");
      };
    });
  }

  sendMessage(message: any): Observable<any> {
    return new Observable((observer) => {
      this.socket.emit("add-message", message, function (response) {
        console.log(`Message sent`);
        observer.next(response);
      });
      return () => {
        this.socket.disconnect();
      };
    });
  }

  receiveMessages(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on("add-message-response", function (data) {
        console.log("add-message-response", data);
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
  }

  uploadChatFile(formdata: any): Observable<any> {
    return this.http.post(
      `${environment.socketUrl}fileUpload`,
      formdata
    );
  }

  uploadFile(file: any): Observable<any> {
    return this.apiService.post(`upload/file`, file);
  }
}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDot]'
})
export class DotDirective {

  constructor(private el: ElementRef) { }
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    
    
      if (event.key == '.' || event.key == 'e' || event.key == 'E' || event.key == '+' || event.key == '-' || event.key == '&' ) {
        event.preventDefault();
      }
   
  }


}


import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "app/core/services/api.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiAdapter } from "./api-adapter.service"
import { environment } from 'environments/environment';
import { IEmployee } from "../model/employee.model";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  constructor(private apiService: ApiService,
    private http: HttpClient,
    private adapter: ApiAdapter) { }

  /*************** Create Employee **************/
  create(formData): Observable<IEmployee> {
    return this.apiService.post(`employee/create`, formData)
  }

  /****************** Get Employee Details ************/
  getEmployeeDetails(paginationDTO): Observable<IEmployee> {
    let params = new HttpParams();
    for (let data in paginationDTO) {
      params = params.set(data, paginationDTO[data]);
    }
    return this.apiService.get(`employee/list`, params).pipe(map((res) => this.adapter.adapt(res)));
  }

  filterby(formData): Observable<any> {
    let params = new HttpParams();
    for (let data in formData) {
      params = params.set(data, formData[data]);
    }
    return this.apiService.get(`employee/list`, params);
  }
  // filterby(formData): Observable<any> {
  //   let params = new HttpParams();
  //   for (let data in formData) {
  //     params = params.set(data, formData[data]);
  //   }
  //   return this.apiService.get(`customer/list`, params);
  // }
  /************* Change Status *************/
  status(body, status) {
    if (status != 2) {
      return this.apiService.post(`employee/status`, body);
    } else return this.apiService.post(`employee/delete`, body);
  }

  /************ Get One Details ********/
  getOneDetails(id): Observable<IEmployee> {
    return this.apiService.get(`employeeview/${id}`).pipe(map((res) => this.adapter.adapt(res)));
  }

  /*************** Update **********/
  Update(body): Observable<IEmployee> {
    return this.apiService.post(`employee/update`, body);
  }

  /*************** Get All Department ***********/
  getdepartment(): Observable<any> {
    return this.apiService.get(`department/getcall`);
  }

  /*************** File Upload ***************/
  uploadProfile(formdata): Observable<any> {
    return this.apiService.post(`upload/file`, formdata);
  }
  
  
  
  
  /****************Task Detail *********************/
  
  taskManagerCount(value): Observable<any> {
    let params = new HttpParams();
    params = params.set('id',value)
    return this.apiService.get(`emp/countSummaryTaskManager`,params);
  }
  
  getToDoList(paginationDTO): Observable<any> {
    let params = new HttpParams();
    for (let key in paginationDTO) {
      params = params.set(key, paginationDTO[key]);
    }
    return this.apiService.get(`emp/todoList`, params);
  }
  
  getInProgressList(paginationDTO): Observable<any> {
    let params = new HttpParams();
    for (let key in paginationDTO) {
      params = params.set(key, paginationDTO[key]);
    }
    return this.apiService.get(`emp/inprogressList`, params);
  }

  getPreviewList(paginationDTO): Observable<any> {
    let params = new HttpParams();
    for (let key in paginationDTO) {
      params = params.set(key, paginationDTO[key]);
    }
    return this.apiService.get(`emp/previewList`, params);
  }

  getCompeletedList(paginationDTO): Observable<any> {
    let params = new HttpParams();
    for (let key in paginationDTO) {
      params = params.set(key, paginationDTO[key]);
    }
    return this.apiService.get(`emp/completedList`, params);
  }

  changeBoardStatus(formData): Observable<any> {
    return this.apiService.post(`emp/statusChange`, formData);
  }
  
  
  
  
  /*****/
  
  
  getOrderTaskDetail(id): Observable<any> {
    return this.apiService.get(`emp/orderItemHistoryView/${id}`);
  }

  getTaskDetail(id): Observable<any> {
    return this.apiService.get(`emp/task/view/${id}`);
  }

  getOrderQcDetail(id): Observable<any> {
    return this.apiService.get(`emp/qcDetails/${id}`);
  }

  getPreviewDetail(id): Observable<any> {
    return this.apiService.get(`emp/previewDetails/${id}`);
  }

  previewAttached(formData): Observable<any> {
    return this.apiService.post(`emp/previewAttachedImageUpload`, formData);
  }

  statusUpdated(formData): Observable<any> {
    return this.apiService.post(`emp/approvedRejectedStatus`, formData);
  }

 

  moveToComplete(formData, type): Observable<any> {
    if (type == 1) {
      return this.apiService.post(`emp/moveToCompleted`, formData);
    } else {
      return this.apiService.post(`emp/onlyQc`, formData);
    }

  }

  moveToOrderQc(formData): Observable<any> {
    return this.apiService.post(`emp/moveToQc`, formData);
  }

  moveToTaskQc(formData): Observable<any> {
    return this.apiService.post(`emp/moveToTaskQc`, formData);
  }

  uploadFile(file) {
    return this.apiService.post(`emp/upload/file`, file)
  }

  removeImage(formData: any) {
    return this.apiService.post(`emp/removeFile`, formData);
  }
  conversationList(taskId): Observable<any> {
    return this.apiService.get(`emp/conversationhistory/view/${taskId}`);
  }

  chatWithAdminInit(formData): Observable<any> {
    return this.apiService.post(`emp/empchatwithadminbox/create`, formData);
  }
  
  getConversationList(request: any): Observable<any> {
    let params = new HttpParams();
    for (let data in request) {
      params = params.append(data, request[data]);
    }
    return this.apiService.get(`admin/chatlist/conversation`, params);
  }
  getToUserId(orderId: any,empId:any): Observable<any> {
    return this.apiService.get(`admin/get/userId/${orderId}/${empId}`);
  }
  getPreviousPreviewDetail(id): Observable<any> {
    return this.apiService.get(`emp/imageVerificationPreviewDetails/${id}`);
  }
}
export interface CHATINTERFACE {
    offset?: any;
    limit?: any;
    totalpages?: any;
    chat_user_id: any;
    order_item_id: any;
}

export class ChatDTO implements CHATINTERFACE {
    offset?: any = 0;
    limit?: any = 10;
    totalpages?: any;
    chat_user_id: any;
    order_item_id: any;
    toUserId: any;
}

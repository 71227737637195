import { Injectable, NgZone } from '@angular/core';

import { mergeMapTo, mergeMap, distinctUntilChanged } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { GenerateRouteService } from './generate-route.service';
import { decodedToken } from '../helpers/token.helper';
import { get } from 'lodash';
import { CurrentUserService } from './current-user.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BellCountService } from './bell-count.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root', // Ensure it's a singleton service
})
export class MessagingService {

    currentMessage = new BehaviorSubject(null);
    removeMessage = new BehaviorSubject(null);
    reloadNotificationList = new BehaviorSubject(null); 
    users: any = [];
    constructor(
        private httpClient: HttpClient,
        private apiService: ApiService,
        private router: Router,
        private ngZone: NgZone,
        private generateRouteService: GenerateRouteService,
        private currentUserService: CurrentUserService,
        private angularFireMessaging: AngularFireMessaging,
        private countService: BellCountService,
        private navService: NavigationService
    ) {
        this.users = this.currentUserService.users;
        this.permission = this.isSupported() ? 'default' : 'denied';
        this.requestPermissions();
    }


    /**
     * request permission for notification from firebase cloud messaging
     *
     * @param userId userId
     */

    requestPermission(userId) {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                console.log(token, 'fcm token');
                const tokens = decodedToken();
                var type = get(tokens, 'type')
                this.sendFCMToken(token);
            }, (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    sendFCMToken(token) {
        let obj = { token: token }
        let portal = decodedToken().portal
        if (portal == 'admin') {
            return this.httpClient.post(environment.apiURL + 'userupdateFcmToken', obj)
                .toPromise()
                .then(this.handleData)
                .catch(this.handleError);
        } else {
            return this.httpClient.post(environment.EmpapiURL + 'employeeFcmTokenUpdate', obj)
                .toPromise()
                .then(this.handleData)
                .catch(this.handleError);
        }


    }

    trackingSendFCMToken(token) {
        const tokens = decodedToken();
        var customerId = get(tokens, 'customer_id')
        var schoolId = get(tokens, 'branch_id')
        var studentId = [];
        this.users.forEach(element => {
            studentId.push(element.student_id);
        });
        let obj = {
            "customerId": String(customerId),
            "schoolId": String(schoolId),
            "studentIds": String(studentId),
            "token": token
        }
        let formData = new FormData();
        formData.append("customerId", customerId ?? '');
        formData.append("schoolId", schoolId ?? '');
        formData.append("studentIds", String(studentId));
        formData.append("token", String(token));
        var url = 'deviceurl' //environment.deviceApiURl ;
        return this.httpClient.put(url + 'student/update/token-detail', formData)
            .toPromise()
            .then(this.handleData)
            .catch(this.handleError);
    }

    private handleData(res: any) {
        const body = res;
        return body || {};
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    /**
     * hook method when new notification received in foreground
     */
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                console.log(payload, 'on foreground');
                this.countService.getForeGroundCount()
                const notifyMsg = payload['data'];
                if (notifyMsg) {
                    notifyMsg.title = notifyMsg.title ? notifyMsg.title : '';
                    notifyMsg.data = notifyMsg.data ? JSON.parse(notifyMsg.data) : '';
                
                  
                    if (notifyMsg.page != 'greeting_createe') {
                        console.log("hitting")
                        this.notify(notifyMsg.title, {
                            body: notifyMsg.body, icon: 'favicon.ico',
                            page: notifyMsg.page, data: notifyMsg.data, portal: notifyMsg.portal, urgency: 'high'
                        });
                        // notification widget
                        
                        this.navService.sendNotificationWidget(notifyMsg)
                        this.currentMessage.next(notifyMsg);
                        this.appendQueueCount(1);
                    }
                } else {
                    if (notifyMsg.page! = 'greeting_create') {
                        let trackingNotify = payload['notification']
                        this.notify(trackingNotify.title, { body: trackingNotify.body, icon: 'favicon.ico' })
                    }
                }
            });
    }

    appendQueueCount(num) {
        let count;
        let oldNotification: any = localStorage.getItem('queueCount');
        let oldNotificationList: any = JSON.parse(oldNotification);
        const append = num != 0 ? (oldNotificationList + 1) : (oldNotificationList - 1);
        this.generateRouteService.setQueueCount(append);
    }

    requestPermissions(): void {
        let self = this;
        if ('Notification' in window) {
            Notification.requestPermission(function (status) {
                console.log("permission status",status)
                return self.permission = status;
            });
        }
    }

    public permission: Permission;

    public isSupported(): boolean {
        return 'Notification' in window;
    }

    notify(title, options) {
        this.generateNotification(title, options);
    }

    generateNotification(title, options): void {
        let self = this;
        let notify = self.create(title, options).subscribe();
    }
    deleteToken() {
        this.angularFireMessaging.getToken
            .pipe(mergeMap(token => this.angularFireMessaging.deleteToken(token)))
            .subscribe((token) => { console.log('Token deleted!'); });
    }
    create(title: string, options?: PushNotification): any {
        console.log("hit create")
        let self = this;
        return new Observable((obs) => {
            // if (!('Notification' in window)) {
            //     obs.complete();
            // }
            if (self.permission != 'granted') {
                obs.complete();
            }
            let _notify = new Notification(title, options);
            _notify.onshow = (e) => { return obs.next({ notification: _notify, event: e }); };
            _notify.onclick = (e) => {
                this.ngZone.run(() => {
                    let parsedData = (options['data']);
                    this.generateRouteService.navigateRouteMessage(decodedToken().portal,options['data'],'')
                    // if (options['portal'] == "admin") {
                    //     this.generateRouteService.navigateRouteForGym(options['portal'], options['page'], parsedData);
                    // }
                    // else {
                    //     this.generateRouteService.navigateRouteForGym(options['portal'], options['page'], parsedData);
                    // }
                });
            };
            _notify.onerror = (e) => { return obs.error({ notification: _notify, event: e }); };
            _notify.onclose = function () { return obs.complete(); };
        });
    }

}

export declare type Permission = 'denied' | 'granted' | 'default';

export interface PushNotification {
    body?: string;
    icon?: string;
    tag?: string;
    data?: any;
    renotify?: boolean;
    silent?: boolean;
    sound?: string;
    noscreen?: boolean;
    sticky?: boolean;
    dir?: 'auto' | 'ltr' | 'rtl';
    lang?: string;
    vibrate?: number[];
}
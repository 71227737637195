import { Injectable } from "@angular/core";
import { ValidationErrors } from "@angular/forms";

@Injectable({
    providedIn: 'root'
})

export class InvalidControlService {
    invalidControls: any = [];
    constructor(){}

    /******* Find Error *****/
    findError(formControl, formControlName){
        let keyErrors: any
        if(formControl?.controls?.length > 0){
            this.findinvalidFormArray(formControl);
            const controlErrors: ValidationErrors = formControl['controls'][this.invalidControls[0]?.index].get(`${this.invalidControls[0]?.key}`).errors;
            Object.keys(controlErrors).forEach(keyError => {
              keyErrors = keyError;
             });
        }else{
            const controlErrors: ValidationErrors = formControl.get(`${formControlName}`).errors;
            Object.keys(controlErrors).forEach(keyError => {
              keyErrors = keyError;
             });
        }
        return keyErrors
    }

    /************ Find Invalid Form Array ********/
    findinvalidFormArray(formControl){
        this.invalidControls = [];
        let formvalue: any = formControl.value;
        formvalue.forEach((element, index) => {
          let formcontrolscontrol = formControl['controls'][index].controls;
          for (const i in formcontrolscontrol) {
            if (formcontrolscontrol[i].invalid) {
              this.invalidControls.push({
                index: index,
                key: i
              });
            }
          }
        });
        return this.invalidControls;
    }
}
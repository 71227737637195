
import { Injectable } from '@angular/core';
import { IChildItem, IMenuItem } from "../models/navigation.model";
import { PORTALTYPE } from "../helpers/enum.helper";
import { decodedToken } from '../helpers/token.helper';
import { get } from "lodash";
import { EmployeeMenu, buywoodExpEmployeeMenu, buywoodMenu } from '../models';

@Injectable({
    providedIn: 'root'
})

export class PermissionService {

    menuSortList: IMenuItem[] = [];
    permissions: Object;
    roleId: number = 0;
    portalType: string = '';

    constructor() {

    }

    hasPermission(moduleList: Array<any>, actionList?: Array<any>): boolean {
        this.autoInit();


        if (this.portalType == PORTALTYPE.ADMIN) {
            if (this.roleId == 1) {
                return true
            }
            else if (this.roleId == 0) {
                return true
            } else {

                if (!actionList) {
                    return this.isModuleHasAction(this.permissions, moduleList);
                }

                if (actionList?.length > 0) {
                    return this.isActionFound(this.permissions, moduleList, actionList);
                }
            }
        } else {
            return true;
        }
    }

    show(moduleList: Array<any>, actionList?: Array<any>): boolean {
        this.autoInit();


        if (this.portalType == PORTALTYPE.ADMIN) {
            if (this.roleId == 1) {
                return true
            }
            else if (this.roleId == 0) {
                return true
            } else {

                if (!actionList) {
                    return this.isModuleHasAction(this.permissions, moduleList);

                }

                if (actionList?.length > 0) {
                    return this.isActionFound(this.permissions, moduleList, actionList);
                }
            }
        } else {
            return true;
        }
    }


    isModuleHasAction(permList: Object, moduleList: Array<any>): boolean {
        let admits = moduleList.filter((value) => {
            if (permList.hasOwnProperty(value)) {
                return this.getValueByKey(permList, value).length > 0;
            }
        });
        return admits.length > 0;
    }

    getValueByKey(object: Object, searchKey: string): Array<any> {

        for (let key in object) {
            if (object.hasOwnProperty(key)) {
                if (key === searchKey) {
                    return object[key] as Array<any>;
                }
            }
        }

        return [];
    }

    isActionFound(permList: Object, moduleList: Array<any>, searchList: Array<any>): boolean {
        let admits = moduleList.filter((value) => {
            if (permList.hasOwnProperty(value)) {
                let moduleActions = this.getValueByKey(permList, value);
                let permittedAction = moduleActions.filter((maction) => {
                    return searchList.indexOf(maction) !== -1;
                });
                return permittedAction.length > 0;
            }
        });
        return admits.length > 0;
    }


    init(roleId: number, portalType: string, permissions: Object) {
        this.roleId = roleId;
        this.portalType = portalType;
        this.permissions = Object.keys(permissions).length > 0 ? permissions : {};
    }

    autoInit() {
        const token = decodedToken();
        this.portalType = get(token, 'portal') ?? '';
        this.roleId = get(token, 'acl_role_id') ?? '';
        this.permissions = get(token, 'permission') ?? {};
    }

    // get authMenu(): IMenuItem[] {
    //     if ((this.roleId == 3 && this.portalType == PORTALTYPE.GYM) || this.portalType == PORTALTYPE.DIETITIAN || this.portalType == PORTALTYPE.PHYSIOTHERAPIST) {
    //         let menu: IMenuItem[] = [];
    //         this.menuSortList = this.menu;
    //         menu = this.menuSortList.filter(data => {
    //             return (data.module?.length > 0 && this.hasPermission(data.module));
    //         });
    //         return menu.map(x => {
    //             if (x?.sub) { x.sub = this.getAuthSubmMenu(x.sub); }
    //             return x;
    //         });
    //     }
    // }


    get authMenu(): IMenuItem[] {
        const token = decodedToken();
        if ((this.roleId == 1 && this.portalType == PORTALTYPE.ADMIN) || this.portalType == PORTALTYPE.EMPLOYEEE) {
            return this.menu;
        }
        let menu: IMenuItem[] = [];
        this.menuSortList = this.menu;
        menu = this.menuSortList.filter(data => {
            return (data.module?.length > 0 && this.hasPermission(data.module));
        });

        return menu.map(x => {
            if (x?.sub) { x.sub = this.getAuthSubmMenu(x.sub); }
            return x;
        });
    }

    getAuthSubmMenu(ichild: IChildItem[]): IChildItem[] {
        let childMenu: IChildItem[] = [];
        childMenu = ichild.filter((data) => {
            return data.module?.length > 0 && this.hasPermission(data.module);
        });
        console.log(ichild, 'child');

        return childMenu.map((x) => {
            if (x?.sub) {
                x.sub = this.getAuthChildSubMenu(x.sub);
            }
            return x;
        });
    }

    getAuthChildSubMenu(ichild: IChildItem[]): IChildItem[] {
        return ichild.filter((data) => {
            return data.module?.length > 0 && this.hasPermission(data.module);
        });
    }

    get menu(): IMenuItem[] {
        let menuList: IMenuItem[] = [];
        let token = decodedToken();
        if (this.portalType == PORTALTYPE.ADMIN) {
            menuList = buywoodMenu;
            console.log(menuList)
        }
        else if (this.portalType == PORTALTYPE.EMPLOYEEE) {
            menuList = EmployeeMenu;
        }
        else {
            menuList = buywoodMenu;
        }
        return menuList;
    }


    getRouterLink(): String {
        const self = this
        function has(modulename): boolean {
            return self.hasPermission([modulename])
        }
        switch (true) {
            case has('Dashboard'): return '/dashboard';
            case has('Passport Size photo'): return '/passportsize-photo';
            case has('Photo Print'): return '/photo-print';
            case has('Photo Frame'): return '/Photoframe';
            case has('Personalized Products'): return '/personalized-products';
            case has('E-commerce Products'): return '/e-commerce-product';
            case has('Selfie Album'): return '/selfie-album';
            case has('Waiting Payments'): return '/waiting-payments';
            case has('Waiting COD'): return '/waiting-cod';
            case has('Waiting Dispatch'): return '/waiting-dispatch';
            case has('Notification'): return '/notification/list';
            case has('Delivery Details'): return '/delivery-details';
            case has('Cancelled Details'): return '/cancelled-details';
            case has('Billing Management'): return '/billing-management';
            case has('Track Order'): return '/track-order';
            case has('Customer'): return '/customer-details';
            case has('Employee'): return '/employee-details';
            case has('Enquiry'): return '/enquiry';
            case has('Quote'): return '/quote';
            case has('Task Manager'): return '/task-manager';
            case has('Payment Transaction'): return '/payment-transaction';
            case has('Refund'): return '/refund';
            // CMS
            case has('CMS Banner'): return '/cms/banner';
            case has('CMS Video'): return '/cms/video';
            case has('CMS Greetings'): return '/cms/greeting';
            // Others
            case has('Contest'): return '/contest';
            case has('Tickets'): return '/ticket';
            case has('Management communication'): return '/manangenet-communication';
            // Reports
            case has('Order Report'): return '/reports/orders-report';
            case has('Refund Report'): return '/reports/refund-report';
            case has('Tickets Report'): return '/reports/ticket-report';
            case has('Gst Report'): return '/reports/gst-report';
            case has('Task Report'): return '/reports/task-report';
            case has('Ratings Review Report'): return '/reports/ratings-review-report';
            case has('Customer Report'): return '/reports/customer-report';
            case has('Employee Report'): return '/reports/employee-report';
            case has('Product Report'): return '/reports/product-report';
            case has('Stock Report'): return '/reports/stock-report';
            case has('Payment Transaction Report'): return '/reports/payments';
            // Settings
            case has('Category'): return '/settings/category-subcategory';
            case has('Variant Type'): return '/settings/variant-type';
            case has('Delivery Management'): return '/settings/delivery-charge';
            case has('Terms and conditions'): return '/settings/terms-conditions';
            case has('Expected Delivery Days'): return '/settings/expected-delivery';
            case has('Shipped Vendor'): return '/settings/shipped-vendor';
            case has('Ratings & Review'): return '/settings/ratings-review';
            case has('Department'): return '/settings/department';
            case has('Task Stages'): return '/settings/task-stages';
            case has('QR Code'): return '/settings/qr-code';
            case has('Task Duration'): return '/settings/task-duration';
            case has('Photo Print Setting'): return '/settings/photo-printing';
            case has('GST Percentage'): return '/settings/gst-percentage';
            case has('Other District Setting'): return '/settings/other-district';
            case has('Change Password'): return '/settings/change-password';
            case has('Coupon Code'): return '/settings/coupon-code';
            case has('Manage Roles'): return '/settings/role';
            case has('Manage Users'): return '/settings/users';
        }

    }



}







const smaple =
{
    "Dashboard": [
        'ffffff'
    ],
    "Category": [],
    "Variant Type": [],
    "Delivery Charge": [],
    "Expected Delivery Days": [],
    "Shipped Vendor": [],
    "Ratings & Review": [],
    "Department": [],
    "Task Stages": [],
    "QR Code": [],
    "Task Duration": [],
    "Photo Print Setting": [],
    "GST Percentage": [],
    "Other District Setting": [],
    "Change Password": [],
    "Coupon Code": [],
    "Manage Roles": [],
    "Manage Users": [],
    "CMS Banner": [],
    "CMS Video": [],
    "CMS Greetings": [],
    "Passport Size photo": [],
    "Photo Print": [],
    "Photo Frame": [],
    "Personalized Products": [],
    "ECommrece Products": [],
    "Selfie Album": [],
    "Order Report": [
        "order_report"
    ],
    "Refund Report": [
        "refund_report"
    ],
    "Tickets Report": [
        "tickets_report"
    ],
    "Gst Report": [
        "gst_report"
    ],
    "Task Report": [],
    "Customer": [],
    "Ratings Review Report": [],
    "Customer Report": [],
    "Employee Report": [],
    "Product Report": [],
    "Stock Report": [],
    "Payment Transaction Report": [],
    "Contest": [],
    "Refund": [],
    "Management communication": [],
    "Tickets": [],
    "Payment Transaction": [],
    "Employee": [],
    "Enquiry": [],
    "Quote": [],
    "Task Manager": []
}
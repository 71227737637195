import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { checkNull } from 'app/core/services/date-and-time-validators.service';
import { EmployeeApiService } from 'app/core/services/employee.api.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private modelService: EmployeeApiService) { }


  getTeleNotificationList(): Observable<any> {
    return this.modelService.get('telecallerNotification/list')
  }
  getWoodExpertNotification(): Observable<any> {
    return this.modelService.get('woodexpertNotification/list')
  }

  setReadStatus(key): Observable<any> {
    return this.modelService.post('readNotification', key);
  }
  setTeleReadStatus(key): Observable<any> {
    return this.modelService.post('telecaller/readNotification', key);
  }
  setWoodReadStatus(key): Observable<any> {
    return this.modelService.post('woodexpert/readNotification', key);
  }


  // Developed Notifications

  // Developed Functionalities

  getNotificationList(): Observable<any> {
    return this.modelService.get(`notification/list`)
  }
  readNotification(dto: any): Observable<any> {
    let params = new HttpParams()
    for (let data in dto) {
      if (checkNull(dto[data])) {
        params = params.append(data, dto[data])
      }
    }
    return this.modelService.post(`readNotification`, params)
  }




}

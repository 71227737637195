import { Injectable } from "@angular/core";
import { Employee } from "../../models/employee/employeee.model";
import { Adapter } from "../../adapters";


@Injectable({
    providedIn: "root"
})
export class EmployeeAdapter implements Adapter<Employee> {
   model: Employee;
   enquiry: Employee;

   /********* adapt Login Details ************/
    adapt(item: any): Employee {
        this.model =  new Employee();    
        this.model.id = item?.id ?? 0,
        this.model.name = item?.name ?? '';
        this.model.email = item?.aud ?? '';
        this.model.mobile = item?.mobile ?? '';
        this.model.token = item?.token ?? '';
        this.model.skybind = item?.rolepermission ?? '';
        this.model.roleId = item?.employee_id ?? '';
        this.model.roletype = item?.employee_type ?? '';
        this.model.otp = item?.otp ?? '';
        return this.model;
    }

    /********* adapt Enquirey list ************/
    adaptenquireylist(item: any): Employee {
        this.enquiry = new Employee();
        this.enquiry.enquiryid = item.enquiry_id;
        this.enquiry.enquirycode = item.enquiry_code;
        this.enquiry.date = item.date;
        this.enquiry.enquiry_creator_name = item.name;
        this.enquiry.district = item.district_id;
        this.enquiry.enquiry_creator_email = item.email;
        this.enquiry.location = item.location;
        this.enquiry.purposeName = item.product_name;
        this.enquiry.purposeNotes = item.enquiry_purpose_notes;
        this.enquiry.districtName = item?.district_name;
        this.enquiry.stateName = item?.state_name;
        this.enquiry.enquiry_creator_mobile = item.mobile;
        this.enquiry.state = item?.state_id;
        this.enquiry.enquiry_creator_status = item?.status
        this.enquiry.createdOn = item?.created_on;
        this.enquiry.updatedOn = item?.updated_on;
        this.enquiry.fileType = item?.document_type;
        this.enquiry.fees = item?.consultation_fees;
        this.enquiry.isConsult = item?.consult_wood_expert;
        this.enquiry.followup = item?.follow_up;
        this.enquiry.notes = item?.notes;
        this.enquiry.reminder = item?.reminder;
        this.enquiry.forwardedemployeeid = item?.forwarded_employee_id;
        this.enquiry.adminstatus = item?.admin_status;

        return this.enquiry;
    }
}
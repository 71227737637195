import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyAuthGuard implements CanActivate {
  constructor(
    private companyService: CompanyService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    return this.companyService.isLoggedIn.pipe(take(1),
      map((isLoggedIn: boolean) => {
        let checkOTP = localStorage.getItem('ISOTPVERIFIED') ? true : false;
        let canLogin = isLoggedIn && checkOTP;
        if (!canLogin) {
          // this.router.navigate(['/']); 
          this.companyService.logout();
          return false;
        }
        return true;
      })
    );
  }
}
<div fxLayout="row">
   
    <div fxFlex="33" class="action-blurb" fxFlexOffset="2">

        <ngx-skeleton-loader
        count="1"
        [theme]="{ 
        
          height: '370px'
         
        }"
      ></ngx-skeleton-loader>


    </div>
    <div fxFlex="50" class="action-blurb" fxFlexOffset="5">

        <ngx-skeleton-loader
        
        count="1"
        [theme]="{ 
        
          height: '30px',
          margin: '10px  5px'
         
        }"
      ></ngx-skeleton-loader>

      <div style="display: flex;">
        <ngx-skeleton-loader
        
        count="1"
        [theme]="{ 
        
          height: '30px',
          margin: '10px  5px',
          width : '100px'
         
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        
      count="1"
      [theme]="{ 
      
        height: '30px',
        margin: '10px  5px',
        width : '100px'
       
      }"
    ></ngx-skeleton-loader>


      </div>
      <div style="display: flex;">
        <ngx-skeleton-loader
        appearance="circle"
        count="1"
        [theme]="{ 
        
          height: '60px',
          margin: '10px  5px',
          width : '60px'
         
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
      appearance="circle"
      count="1"
      [theme]="{ 
      
        height: '60px',
        margin: '10px  5px',
        width : '60px'
       
      }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
    appearance="circle"    
    count="1"
    [theme]="{ 
    
        height: '60px',
          margin: '10px  5px',
          width : '60px'
     
    }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
  appearance="circle"
  count="1"
  [theme]="{ 
  
    height: '60px',
    margin: '10px  5px',
    width : '60px'
   
  }"
></ngx-skeleton-loader>


      </div>

   


    <ngx-skeleton-loader
        
    count="1"
    [theme]="{ 
    
      height: '30px',
      margin: '10px  5px',
      width: '170px'
     
    }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
        
  count="1"
  [theme]="{ 
  
    height: '90px',
    margin: '10px  5px'
   
  }"
></ngx-skeleton-loader>

    </div>



</div>

<div fxLayout="row">

  <div fxFlex="95" class="action-blurb" fxFlexOffset="2">


    <ngx-skeleton-loader
    count="1"
    [theme]="{ 
    
      height: '150px'
     
    }"
  ></ngx-skeleton-loader>
  </div>


</div>

<div fxLayout="row">

  <div fxFlex="20" class="action-blurb" fxFlexOffset="4">


    <ngx-skeleton-loader
    count="1"
    [theme]="{ 
    
      height: '350px'
     
    }"
  ></ngx-skeleton-loader>
  </div>
  <div fxFlex="20" class="action-blurb" fxFlexOffset="4">


    <ngx-skeleton-loader
    count="1"
    [theme]="{ 
    
      height: '350px'
     
    }"
  ></ngx-skeleton-loader>
  </div>
  <div fxFlex="20" class="action-blurb" fxFlexOffset="4">


    <ngx-skeleton-loader
    count="1"
    [theme]="{ 
    
      height: '350px'
     
    }"
  ></ngx-skeleton-loader>
  </div>
  <div fxFlex="20" class="action-blurb" fxFlexOffset="4">


    <ngx-skeleton-loader
    count="1"
    [theme]="{ 
    
      height: '350px'
     
    }"
  ></ngx-skeleton-loader>
  </div>



</div>
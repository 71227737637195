import { HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from 'app/core/services/api.service';
import { checkNull } from 'app/core/services/date-and-time-validators.service';
import { Observable } from 'rxjs';
import { Inotification } from './notification-constants';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  readonly notificationList$: EventEmitter<any> = new EventEmitter<any>();
  constructor(private apiservice: ApiService) { }


  getAllNotification(): Observable<any> {
    return this.apiservice.get(`notification/list`)
  }

  setReadStatus(key): Observable<any> {
    return
  }

  setNotificationList(property: any, storeProp: boolean = false) {
    if (storeProp) {
      this.notificationList$.emit(storeProp);
    }
  }


  // Developed Functionalities

  getNotificationList(): Observable<any> {
    return this.apiservice.get(`notification/list`)
  }
  readNotification(dto: any): Observable<any> {
    let params = new HttpParams()
    for (let data in dto) {
      if (checkNull(dto[data])) {
        params = params.append(data, dto[data])
      }
    }
    return this.apiservice.post(`readNotification`, params)
  }



}

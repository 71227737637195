import { Injectable } from "@angular/core";
import { Adapter } from "app/core/adapters";
import { Employee } from "app/shared/models/employee/employee";


@Injectable({
    providedIn: "root"
})
export class EmployeeAdapter implements Adapter<Employee> {
    employee: Employee;
    adapt(item: any): Employee {
        this.employee =  new Employee();        
        this.employee.employeeType = item.employee_type;
        this.employee.name = item.employee_name;
        this.employee.photourl = item.employee_image_url;
        this.employee.photo = item.employee_image;
        this.employee.department = item.department_id;
        this.employee.mobile = item.mobile_no;
        this.employee.departmentname = item.department_name;
        this.employee.email = item.email;
        this.employee.id = item.employee_id;
        this.employee.status = item.status;
        this.employee.created_on = item.created_on;
        this.employee.employee_code = item.employee_code;
        this.employee.work_status = item.work_status;
        return this.employee;
    }
}